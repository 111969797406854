//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { bootstrapApp } from '@/services/app-ai.service';
import windowResizeMixin from '@motionelements/core/src/components/mixins/window-resize.mixin.js';

export default {
  name: 'BaseLayout',
  mixins: [windowResizeMixin],
  components: {
    PageMeta: () => import('@motionelements/core/src/components/ThePageMeta.vue'),
    Blocker: () => import('@motionelements/core/src/components/ui/Blocker.vue'),
    ShinySvgGradient: () => import('@motionelements/core/src/components/product/ShinySvgGradient.vue'),
    FlashNotifications: () => import('@/components/core/notifications/BaseFlashNotifications.vue'),
    ToastNotifications: () => import('@/components/core/notifications/BaseToastNotifications.vue'),
    SignUpModal: () => import('@/components/modals/SignUpModal.vue'),
    OptimizeExperienceModal: () => import('@/components/modals/OptimizeExperienceModal.vue'),
    FeedbackModal: () => import('@/components/modals/FeedbackModal.vue'),
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      bodyAttrs: {
        class: ['ui-dark'],
      },
    };
  },
  beforeCreate() {
    if (!process.server) {
      bootstrapApp(this);
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.user.isLoggedIn,
      showBlocker: state => state.blocker.show,
      blockerMessage: state => state.blocker.message,
    }),
    pageStyle() {
      if (process.client && process.env.VUE_APP_ENV !== 'production') {
        if (this.$route.query.screenshot_lokalise) {
          return 'white-space: nowrap !important; font-family: Arial, Helvetica, sans-serif !important;';
        }
      }
      return '';
    },
  },
};
